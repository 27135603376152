import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { saveAs } from 'file-saver';
import { Observable } from 'rxjs';
import { environment } from '../../../environments/environment';
import { ExportJob } from '../all.types';

@Injectable()
export class ExportCsvService {
  private _baseUrl = `${environment.api.baseUrl}/export/`;

  constructor(private readonly http: HttpClient) {}

  public downloadFile(data: Blob, fileName: string, type: string): void {
    if (type === 'csv') {
      const bom = '\uFEFF';
      data = new Blob([bom, data], { type: 'application/zip;charset=utf-8' });
    }

    saveAs(data, fileName);
  }

  public getExportJob(jobId: number): Observable<ExportJob> {
    const url = `${this._baseUrl}${jobId}`;

    return this.http.get<ExportJob>(url, { withCredentials: true });
  }

  public fetchFile(jobId: number, type: string): Observable<Blob> {
    const url = `${this._baseUrl}${jobId}/file?type=${type}`;

    return this.http.get<Blob>(url, { withCredentials: true, responseType: 'blob' as 'json' });
  }
}
